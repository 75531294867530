import React from "react";
import uvci from "../../assets/images/uvci.png"

const PartnersSection: React.FC = () => {
  const partners = [{ name: "UVCI", logo: uvci }];

  return (
    <section className="bg-gray-50 py-16 px-6 sm:px-12 lg:px-24">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-extrabold text-blue-900 sm:text-5xl">
          Ces organisations qui nous font confiance
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Découvrez les entités qui collaborent avec MANIA pour l'innovation et l'intelligence artificielle.
        </p>
      </div>

      {/* Partners Logo Scrolling */}
      <div className="relative overflow-x-auto">
        <div
          className={`flex space-x-12 ${
            partners.length > 1 ? "animate-scroll" : "justify-center"
          }`}
        >
          {partners.map((partner, index) => (
            <div
              key={index}
              className="flex flex-col items-center transition-all transform hover:scale-110 hover:shadow-2xl hover:transition duration-300 ease-in-out"
            >
              <img
                src={partner.logo}
                alt={partner.name}
                className="w-32 h-32 object-contain mb-2"
              />
              {/* <span className="text-sm text-gray-700">{partner.name}</span> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
