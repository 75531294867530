import React, { FC, InputHTMLAttributes, useEffect, useState } from 'react'

interface InfoProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  inputType: string;
  name?: string;
  onChange?: any;
  value?: any;
  wrapperClassName?: string;
  sizeH?: string;
};

const AnimatedInput: FC<InfoProps> = (props) => {

  const { label, inputType, name, onChange, value, wrapperClassName , sizeH = "h-[50px] lg:h-[70px]"} = props

  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);


  const onInputChange = (e: any) => {
    setInputValue(e.target.value);
    onChange && onChange(e)
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };


  return (
    <div className={`${wrapperClassName} relative w-full ${sizeH} flex items-center rounded-[24px] ${isFocused ? ' border-[#B3916E] border-[1px]' : 'border-[#DADADA] border-[1px]'}`}>

      <label className={`absolute left-3 transition-all text-xs px-4
                        ${isFocused || value ? "font-bold top-0 -translate-y-[10px] bg-gradient-to-bl from-[#F86761] to-[#FEA07D] text-sm text-white z-[3]" : "font-medium translate-y-4 -top-2.5 lg:translate-y-[27px] bg-[#FFFFFF] text-[22px] text-[#0D2C3B] text-opacity-50 z-[3]"}`}>
        {label}
      </label>
      {
        inputType === 'textArea'
          ?
          <textarea
            value={value ?? inputValue}
            onChange={(event) => onInputChange(event)}
            onFocus={() => onFocus()}
            onBlur={() => onBlur()}
            name={name}
            className={`border-none w-full h-full rounded-[24px] cursor-pointer transition ease-out bg-[#FFFFFF] focus:ring-0 relative z-[2] resize-none`}
            cols={30}
            rows={10}

          />
          :
          <input
            value={value ?? inputValue}
            onChange={(event) => onInputChange(event)}
            onFocus={() => onFocus()}
            onBlur={() => onBlur()}
            type={inputType}
            name={name}
            className={`border-none w-full h-full rounded-[24px] cursor-pointer transition ease-out bg-[#FFFFFF] focus:ring-0 relative z-[2]`
            }
            
          />
      }
    </div>
  )
}

export default AnimatedInput