import { combineReducers } from "redux";
// import { combineReducers  } from '@reduxjs/toolkit';
import { connectRouter } from "connected-react-router";

import history from '../history';

import { SidebarReducer } from './SidebarReducer';

const rootReducer = combineReducers({
  router: connectRouter(history),
  SidebarReducer,
});

/**
 * reducers combine here
 * @param {*} state 
 * @param {*} action 
 * @returns root reducers
 */

const appReducer = (state: any, action: { type: string; }) => {

//   if (action.type === "") {
//     return rootReducer(undefined, action); // clean store for a specific action type
//   }

  return rootReducer(state, action);
};

export default appReducer;
export type RootState = ReturnType<typeof rootReducer>;