import randomId from "../../common/randomId";


export const NAVIGATION_MENU: any[] = [
  {
    id: randomId(),
    href: "/",
    name: "Catalogue",
    // type: "dropdown",
    // children: demoChildMenus,
    // isNew: true,
  },
  {
    id: randomId(),
    href: "/",
    name: "Entreprise",
    // type: "dropdown",
    // children: templatesChildrenMenus,
  },
  {
    id: randomId(),
    href: "/",
    name: "Universités",
    // type: "dropdown",
    // children: templatesChildrenMenus,
  }
];