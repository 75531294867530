import React, { FC, InputHTMLAttributes, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

interface InfoProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    inputType: string;
    name?: string;
    onChange?: any;
    value?: any;
    wrapperClassName?: string;
    sizeH?: any;
};

const AnimatedTelInput: FC<InfoProps> = (props) => {

    const [phone, setPhone] = useState('');

    const { label, inputType, name, onChange, value, wrapperClassName, sizeH="h-[50px] lg:h-[70px]" } = props

    const [inputValue, setInputValue] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    const onInputChange = (e: any) => {

        setInputValue(e);
        onChange && onChange(e)
        setPhone(e);      
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className={`${wrapperClassName} relative w-full ${sizeH} bg-[#FFFFFF] flex items-center ${isFocused || inputValue || phone ? ' border-[#B3916E] border-[1px]' : 'border-[#DDDDDD] border'}`}>

            <label className={`absolute top-0 left-3 transition-all text-xs font-medium  px-2
                        ${isFocused || value ? "-translate-y-[10px] bg-white text-sm text-[#B3916E] z-[50]" : "translate-y-4  -top-3 lg:translate-y-[27px] bg-white text-[22px] text-[#0D2C3B] text-opacity-50 z-[9]"}`}>
                {label}
            </label>

            <div
                className={`border-none w-full ${wrapperClassName} ${sizeH}  rounded-[24px] cursor-pointer transition ease-out bg-[#FFFFFF] focus:ring-0 relative z-[11] ${isFocused || inputValue ? "opacity-100" : "opacity-0"}`}
                onFocus={() => onFocus()}
                onBlur={() => onBlur()}
            >
                <PhoneInput
                    initialCountry="ci"
                    value={phone ?? value ?? inputValue}
                    onChange={(event: any) => onInputChange(event)}
                    className={`border-none  w-full h-full top-[4px] left-[8px] rounded-[24px] cursor-pointer transition ease-out bg-transparent focus:ring-0 relative z-[2]`}
                />
            </div>

        </div>
    )
}

export default AnimatedTelInput