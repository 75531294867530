import React from "react";

const DemarcheFive2: React.FC = () => {
  return (
    <section className="bg-gray-50 py-12 px-6 sm:px-12 lg:px-24">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-extrabold text-blue-900 sm:text-5xl mt-4">
        Nos 5 piliers pour la promotion de l’IA
        </h2>
        <p className="mt-4 text-gray-600 max-w-3xl mx-auto">
          Découvrez nos initiatives pour transformer l'Afrique grâce au
          numérique et à l'intelligence artificielle. MANIA se concentre sur la
          formation, l'innovation, la veille, et l'émulation pour bâtir un
          avenir technologique durable.
        </p>
      </div>

      {/* Card Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {/* Card 1 - Formation */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center border-t-4 border-blue-900">
          <div className="text-blue-900 mb-4">
            <i className="fas fa-graduation-cap text-4xl"></i>
          </div>
          <h3 className="text-xl font-semibold text-blue-900">Formation</h3>
          <p className="mt-4 text-gray-600">
          Des ressources humaines qualifiées capables de relever les défis de l'ère du numérique en faveur du développement de l'Afrique.
          </p>
          <button className="mt-6 px-4 py-2 bg-blue-900 text-white font-semibold rounded-lg hover:bg-blue-700 transition">
            Découvrez notre catalogue de formation
          </button>
        </div>

        {/* Card 2 - Innovation */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center border-t-4 border-blue-900">
          <div className="text-blue-900 mb-4">
            <i className="fas fa-lightbulb text-4xl"></i>
          </div>
          <h3 className="text-xl font-semibold text-blue-900">Innovation</h3>
          <p className="mt-4 text-gray-600">
          Un écosystème de la tech maitrisé afin d'accompagner les startups et la recherche sur des projets d'innovation à fort impact socio-économique. 
          </p>
          <button className="mt-6 px-4 py-2 bg-blue-900 text-white font-semibold rounded-lg hover:bg-blue-700 transition">
            Réservez un accompagnement
          </button>
        </div>

        {/* Card 3 - Veille & Vulgarisation */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center border-t-4 border-blue-900">
          <div className="text-blue-900 mb-4">
            <i className="fas fa-newspaper text-4xl"></i>
          </div>
          <h3 className="text-xl font-semibold text-blue-900">
            Veille & Vulgarisation
          </h3>
          <p className="mt-4 text-gray-600">
          Une population africaine acculturée dès le jeune âge à l'innovation, la technologie et au fait des mutations du numérique et de l'IA
          </p>
          <button className="mt-6 px-4 py-2 bg-blue-900 text-white font-semibold rounded-lg hover:bg-blue-700 transition">
          Découvrez l’actu Tech & IA
          </button>
        </div>

        {/* Card 4 - Emulation */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center border-t-4 border-blue-900">
          <div className="text-blue-900 mb-4">
            <i className="fas fa-trophy text-4xl"></i>
          </div>
          <h3 className="text-xl font-semibold text-blue-900">Émulation</h3>
          <p className="mt-4 text-gray-600">
          Un vivier de talents révélés et des vocations suscitées à travers des hackathons, des prix d'excellence et des évènements "tech" d'envergure.
          </p>
          <button className="mt-6 px-4 py-2 bg-blue-900 text-white font-semibold rounded-lg hover:bg-blue-700 transition">
            Participez à nos compétitions
          </button>
        </div>
      </div>
    </section>
  );
};

export default DemarcheFive2;
