import React, { FC, ReactNode } from 'react';

interface StyleButtonProps {
  handleClick: any,
  className?: string,
  content?: string,
  bgColor?: string,
  sizeWH?: string,
  roundedStyle?: string,
  textSize?: string,
  fontStyle?: string,
  extraClasses?: string
};

const StyleButton: FC<StyleButtonProps> = ({
  bgColor='bg-[#FFFFFF]',
  className = "",
  content,
  handleClick,
  sizeWH = "h-[40px]",
  roundedStyle="rounded-[8px]",
  textSize = "text-[14px]",
  fontStyle = "font-semibold",
  extraClasses
 }) => {

  const handleClickEvent = () => {
    handleClick && handleClick();
  };


  return (
    <button
      onClick={(e: any) => handleClickEvent()}
      className={`relative group overflow-hidden space-x-2 py-1 px-3 ${sizeWH} flex justify-center items-center ${roundedStyle} ${bgColor} transition duration-300 transform active:scale-95 ease-in-out ${className} ${extraClasses}`}>
        {/* <img src = {icon} /> */}
        <span className={`font-playfair-display ${fontStyle} inline-block ${textSize} text-[#FFFFFF] leading-[20px]`}>
          { content }
        </span>
    </button>
  )
}

export default StyleButton;