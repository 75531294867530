import React from "react";

const UpcomingEvent: React.FC = () => {
  return (
    <section className="bg-blue-50 py-16 px-6 sm:px-12 lg:px-24">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-extrabold text-blue-900 sm:text-5xl">
          Prochain Événement MANIA
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Rejoignez-nous pour notre prochain événement MANIA, où l'innovation rencontre l'intelligence artificielle.
        </p>
      </div>

      {/* Event Card */}
      <div className="flex flex-col lg:flex-row items-center bg-white rounded-lg shadow-xl overflow-hidden">
        {/* Image Section */}
        <div className="w-full lg:w-1/2">
          <img
            src="https://via.placeholder.com/800x500?text=MANIA+Event+Image" // Placeholder image for event
            alt="Upcoming MANIA Event"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Text Content */}
        <div className="w-full lg:w-1/2 p-8 flex flex-col justify-center">
          <h3 className="text-3xl font-bold text-blue-900">L'Innovation au Coeur de MANIA</h3>
          <p className="text-sm text-gray-500 mt-2">Date: 15 Janvier 2025 | Heure: 14h00</p>
          <p className="mt-6 text-lg text-gray-700">
            Ne manquez pas l'événement incontournable où des experts de l'IA, des chercheurs, et des acteurs de l'innovation se réunissent pour discuter des dernières tendances en intelligence artificielle.
          </p>

          {/* Enhanced CTA Buttons */}
          <div className="mt-8 flex space-x-4">
            <a
              href="#"
              className="inline-block bg-gradient-to-r from-blue-600 to-indigo-800 text-white py-3 px-8 rounded-full text-lg font-semibold shadow-lg transform transition duration-300 hover:scale-105 hover:from-blue-700 hover:to-indigo-900"
            >
              En savoir plus
            </a>
            <a
              href="#"
              className="inline-block bg-gradient-to-bl from-[#F86761] to-[#FEA07D] text-white py-3 px-8 rounded-full text-lg font-semibold shadow-lg transform transition duration-300 hover:scale-105 hover:from-green-700 hover:to-teal-700"
            >
              S'inscrire
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvent;
