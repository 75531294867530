import React from 'react';
import { Blogs, Collaborations, Contactus, DemarcheFive, Facilities, Gallery, HeroMaster, QuantityByCategory } from '../../components';
import shape from '../../assets/images/shape.png';
import HeroCarouselSlider from '../../components/Hero/HeroCarouselSlider';
import Aboutus from '../../components/Aboutus/Aboutus';
import Partners from '../../components/Partners/Partners';
import Ctabanner from '../../components/Ctabanner/Ctabanner';
import Hero from '../../components/Hero/Hero';
import DemarcheFive2 from '../../components/DemarcheFive/DemarcheFive1';
import AINewsSection from '../../components/AINews/AINewsSection';
import UpcomingEvent from '../../components/UpcomingEvent/UpcomingEvent';
import PartnersSection from '../../components/Partners/PartnersSection';

const Homepage = () => {
  return (
    <div className='min-h-screen'>
        <Hero />
        {/* <HeroCarouselSlider /> */}
        {/* <Aboutus /> */}
        {/* <Collaborations /> */}
        {/* <QuantityByCategory /> */}
        <DemarcheFive2 />
        <AINewsSection />
        <UpcomingEvent />
        <PartnersSection />
        {/* <Ctabanner /> */}
        {/* <Collaborations />  */}
        {/* <Facilities /> */}
        {/* <div className='relative'>
          <div className='absolute mt-72 ml-24 -z-40'>
            <img src={shape} />
          </div>
          <Gallery />
        </div> */}
        {/* <Blogs /> */}
        {/* <div className='flex relative'>
          <Contactus />
          <div className='absolute ml-[84.03vw] mt-[250px] z-40'>
            <img src={shape} className='-z-40' />
          </div>
        </div> */}
    </div>
  )
};

export default Homepage;