import React, { useState, useEffect, useRef } from "react";
import Logo from "../../assets/logo/logo.png"; // Ajuste le chemin de ton logo

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  // Gérer l'effet de scroll pour modifier le background de la navbar
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Active l'effet après 50px de scroll
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Fermer le menu si l'utilisateur clique en dehors
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !menuButtonRef.current?.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-md" : "bg-transparent"
      }`}
    >
      <div className="flex items-center justify-between px-6 py-3 h-20 transition-all">
        {/* Logo */}
        <div className="flex items-center">
          <img src={Logo} alt="SIFIA Logo" className="h-24 md:h-44 transition-all duration-300" />
        </div>

        {/* Menu Desktop */}
        <div className="hidden lg:flex flex-grow items-center justify-center space-x-6">
          {/* <div className="flex items-center border border-blue-900 rounded-full px-4 py-1 shadow-sm w-1/3 bg-white">
            <input
              type="text"
              placeholder="Vous cherchez une formation ?"
              className="flex-grow border-none focus:outline-none text-gray-700 text-sm bg-transparent"
            />
            <button className="text-blue-900 font-medium hover:underline text-sm">
              Explorer
            </button>
          </div> */}

          <div className="flex space-x-6 text-blue-900 text-sm">
            <a href="#" className="font-semibold hover:text-[#ed4b45] transition">Qui sommes-nous</a>
            <a href="#" className="font-semibold hover:text-[#ed4b45] transition">Catalogue</a>
            <a href="#" className="font-semibold hover:text-[#ed4b45] transition">Contacts</a>
          </div>
        </div>

        {/* Bouton de connexion */}
        <div className="hidden md:flex items-center">
          <button className="font-semibold bg-gradient-to-r from-blue-900 to-blue-600 text-white py-2 px-6 rounded-full hover:opacity-90 transition duration-300 transform active:scale-95">
            Se connecter
          </button>
        </div>

        {/* Bouton Mobile */}
        <div className="md:hidden">
          <button ref={menuButtonRef} onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-blue-900 focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
            </svg>
          </button>
        </div>
      </div>

      {/* Menu Mobile */}
      <div
        ref={menuRef}
        className={`absolute top-0 left-0 w-full bg-white shadow-md transform ${
          isMenuOpen ? "translate-y-0" : "-translate-y-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <div className="flex flex-col items-center py-6 space-y-4 text-blue-900 text-sm">
          {/* <div className="flex items-center border border-blue-900 rounded-full px-4 py-2 shadow-sm w-10/12">
            <input type="text" placeholder="Vous cherchez une formation ?" className="flex-grow border-none focus:outline-none text-gray-700 text-sm" />
            <button className="text-blue-900 font-medium hover:underline text-sm">Explorer</button>
          </div> */}

          <a href="#" className="font-semibold hover:text-blue-600 transition">Qui sommes-nous</a>
          <a href="#" className="font-semibold hover:text-blue-600 transition">Catalogue</a>
          <a href="#" className="font-semibold hover:text-blue-600 transition">Contacts</a>

          <button className="font-semibold bg-gradient-to-r from-blue-900 to-blue-600 text-white py-2 px-6 rounded-full hover:opacity-90 text-sm transition transform active:scale-95">
            Se connecter
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
