import React, { Suspense, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate
} from 'react-router-dom';
import viewsUrls from "./routes";

function Routers(props: any) {

  return (
      <Routes>
        {
          viewsUrls?.map(({ component, path, exact, type, id}: any) => {
            let Component = component;
            return (
              <Route 
                key={id}
                path={path} 
                element = { <Component /> } 
              />
            );
          })
        }
       {/* <Route path="/404" element = { <ErrorPage />} /> */}
      </Routes>
  );
}

export default Routers;
