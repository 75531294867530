import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo_white from '../../assets/logo/logo-white.png';
import AnimatedInput from '../AnimatedInput/AnimatedInput';
import { StyleButton } from '../../containers';
import useWindowSize from '../../hooks/useWindowSize';

const MasterFooter = () => {
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    setIsMobile((prevState: any) => {
      if (!prevState && windowSize.width < 768) {
        return true;
      } else if (prevState && windowSize.width > 768) {
        return false;
      } else {
        return prevState;
      }
    });
  }, [windowSize]);

  return (
    <div className={`sticky top-[100vh] bottom-0 ${isMobile && 'h-full'} md:h-[448px] bg-gradient-to-l from-[#262262] to-[#4B66BA]`}>
      <div className='container grid grid-cols-2 md:flex md:flex-row md:justify-between py-16'>
        <div className='-mt-5 md:mt-0 flex flex-col space-y-4 md:space-y-8'>
          <div>
            <Link to={`/`} onClick={(e: any) => { }} className="ttnc-logo inline-block text-primary-6000">
              <img className="block w-[163px] md:w-[263px] h-[77px] object-cover" src={logo_white} alt="Our Logo" />
            </Link>
          </div>
          <div className='px-4 md:px-8 container font-red-hat-text font-normal md:w-[370px] text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px]'>
            Maison Africaine du Numérique de l'Intelligence Artificielle
          </div>
        </div>

        {/* Explore section */}
        <div className='flex flex-col space-y-6'>
          <div className='font-bold text-[16px] md:text-[16px] text-[#FFFFFF] uppercase leading-[32px]'>Explorer</div>
          <div className='flex flex-col space-y-4'>
            <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>À propos</span>
            <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>Apprentissage en ligne</span>
            <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>Solutions d'IA</span>
            <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>Blog</span>
          </div>
        </div>

        {/* About MANIA section */}
        <div className='px-4 md:px-0 mt-4 md:mt-0 flex flex-col space-y-6'>
          <div className='font-bold text-[16px] text-[#FFFFFF] uppercase leading-[32px]'>Connaitre MANIA</div>
          <div className='flex flex-col space-y-4'>
            <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>La raison d’être MANIA</span>
            <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>Vision & Mission</span>
            <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>Connaitre l'équipe</span>
          </div>
        </div>

        {/* Newsletter section */}
        <div className='mt-4 md:mt-0 flex flex-col w-[170px] md:w-[370px]'>
          <div className='font-bold text-[16px] text-[#FFFFFF] uppercase leading-[32px]'>Newsletters</div>
          <AnimatedInput
            inputType='text'
            label='E-Mail Address'
            name='email'
            sizeH="w-[370px] h-[48px]"
            wrapperClassName='mt-6 rounded-[24px]'
          />
          <div className='flex justify-end py-4 '>
            <StyleButton content='Submit' sizeWH="w-[100px] h-[35px] md:w-[170px] md:h-[48px]" roundedStyle="rounded-[24px]" bgColor="bg-gradient-to-bl from-[#F86761] to-[#FEA07D]" handleClick={undefined} />
          </div>
        </div>
      </div>

      <div className="container w-full border-[1px] border-[#FFFFFF] opacity-10 dark:border-neutral-700"></div>

      <div className='container px-4 md:px-0 flex flex-col md:flex-row justify-between mt-10'>
        <div className='font-red-hat-text font-normal leading-[32px] text-[#FFFFFF] text-[14px] md:text-[18px]'>
          Copyright © 2024 eMANIA.org. All Rights Reserved.
        </div>
        <div className='flex flex-row space-x-4'>
          <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>Terms of Conditions</span>
          <span className='font-red-hat-text font-normal text-[14px] md:text-[18px] text-[#FFFFFF] leading-[32px] cursor-pointer'>Privacy Policy</span>
        </div>
      </div>

      {/* LDS Signature Section */}
      <div className='container px-4 md:px-0 flex flex-col md:flex-row justify-center mt-10'>
        <span className='flex flex-col md:flex-row justify-center items-center md:space-x-[2px] font-red-hat-text font-normal text-[14px] md:text-[16px] text-[#fff] md:text-[#000] leading-[32px]'>
        <span>Développé avec passion par  </span> 
          <a
            href="https://www.loven-soft.com"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-[#FEA07D] hover:underline"
          >
            LSD - LOVEN SOFTWARE DEVELOPMENT
          </a>
        </span>
      </div>
    </div>
  );
};

export default MasterFooter;
