import randomId from "../common/randomId";
import { Dashboard, Homepage, Login } from "../views";

const viewsUrls: any[] = [
    {
        id: randomId(),
        path: "/",
        exact: true,
        component: Homepage,
        type: "public"
    },
    {
        id: randomId(),
        path: "/login",
        exact: true,
        component: Login,
        type: "public"
    },
    {
        id: randomId(),
        path: "/dashboard",
        exact: true,
        component: Dashboard,
        type: "private"
    }
];

export default viewsUrls;