export default function randomId(prefix = "tp_") {
  return (
    prefix + Date.now() + "_" + Math.floor(Math.random() * 9999999999)
  );
};

const generateCharCode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

  const uniqueId = function () {
    let text = ''
    for (let i = 0; i < 7; i++) {
      text += characters.charAt(Math.floor( Math.random() * characters.length ))
    }
    return text;
  };
  
  return uniqueId()
};

const generateBookingCode = (BOOKING_TYPE: any) => {
  let today = new Date().toISOString().split('T')[0];
  let daycode = today.replaceAll("-", "");

  return (
    // daycode + generateCharCode()+"-" + Date.now() +"-"+ BOOKING_TYPE
    daycode + "-" + generateCharCode() + "-" + BOOKING_TYPE
  );
};

export { generateBookingCode };