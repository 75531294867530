import React, { useState, useEffect } from "react";
import clsx from "clsx";
import hero1 from "../../assets/hero1.png";
import hero2 from "../../assets/hero2.png";
import hero3 from "../../assets/hero3.png";

const images = [hero1, hero2, hero3];

const Hero: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-screen w-full overflow-hidden">
      {/* Background Images */}
      <div className="absolute inset-0 w-full h-full">
        {images.map((image, index) => (
          <div
            key={index}
            className={clsx(
              "absolute inset-0 transition-opacity duration-1000",
              {
                "opacity-0": currentImage !== index,
                "opacity-100": currentImage === index,
              }
            )}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        ))}
      </div>

      {/* Text and CTA Buttons */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white bg-black bg-opacity-40 p-6 md:p-12">
        <h1 className="font-playfair-display text-5xl md:text-[60px] lg:text-[72px] font-bold leading-tight tracking-tight">
          Maison Africaine du Numérique et de l'Intelligence Artificielle
        </h1>
        <p className="mt-4 text-lg md:text-xl lg:text-2xl font-light opacity-90">
        Un centre d’excellence, accélérateur de l’innovation et l’intelligence artificielle en Afrique
        </p>
        <div className="mt-8 flex flex-wrap justify-center gap-8">
          {/* Button Row */}
          <div className="flex flex-wrap justify-center gap-6 md:gap-8">
            <button className="font-semibold bg-gradient-to-bl from-[#262262] to-[#4B66BA] text-white text-[17px] rounded-[34px] py-4 px-8 md:px-12 hover:bg-white hover:text-[#262262] hover:shadow-xl transition-all duration-300 transform active:scale-95 ease-in-out">
              Se Former
            </button>
            <button className="font-semibold bg-gradient-to-bl from-[#F86761] to-[#FEA07D] text-white text-[17px] rounded-[34px] py-4 px-8 md:px-12 hover:bg-white hover:text-[#F86761] hover:shadow-xl transition-all duration-300 transform active:scale-95 ease-in-out">
              Découvrir nos offres
            </button>
            <button className="font-semibold bg-gradient-to-bl from-[#262262] to-[#4B66BA] text-white text-[17px] rounded-[34px] py-4 px-8 md:px-12 hover:bg-white hover:text-[#F86761] hover:shadow-xl transition-all duration-300 transform active:scale-95 ease-in-out">
              Nous Rejoindre
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
