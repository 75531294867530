import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";

import './App.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import store from './store';
import history from './store/history';
import { Routers } from './routers';
import { HeroRightBottomBackground, MasterFooter, MasterHeader, TopLeftBackground } from './components';
import MasterHeader2 from './components/MasterHeader/MasterHeader2';
import Navbar from './components/MasterHeader/Navbar';


function App() {

  return (
    <Provider store={store}>
      {/* @ts-ignore */}
      <Router history={history}>
        <div className='relative'>
          {/* <TopLeftBackground /> */}
          <Navbar />
          {/* <MasterHeader /> */}
          {/* <HeroRightBottomBackground /> */}
          <Routers />

          <div className="relative">
            {/* <SectionBackground className="bg-[#0E123D] dark:bg-black dark:bg-opacity-20 " /> */}
            <MasterFooter />
          </div>
        </div>
      </Router>
    </Provider>

  );
};

export default App;
