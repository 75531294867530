import React from "react";

interface NewsCardProps {
  title: string;
  date: string;
  imageUrl: string;
  preview: string;
  link: string;
  reverse?: boolean; // To alternate image and text alignment
}

const NewsCard: React.FC<NewsCardProps> = ({
  title,
  date,
  imageUrl,
  preview,
  link,
  reverse = false,
}) => {
  return (
    <div
      className={`flex flex-col ${
        reverse ? "lg:flex-row-reverse" : "lg:flex-row"
      } bg-white shadow-lg rounded-lg overflow-hidden mb-12 w-full`}
      style={{ minHeight: "400px" }} // Increased card height
    >
      {/* Image Section */}
      <div className="w-full lg:w-1/2 relative bg-gray-200">
        <img
          src={imageUrl || "https://via.placeholder.com/600x400?text=Image"} // Placeholder image
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Text Content */}
      <div className="w-full lg:w-1/2 p-8 flex flex-col justify-center">
        <h3 className="text-2xl font-bold text-blue-900">{title}</h3>
        <p className="text-sm text-gray-500 mt-2">{date}</p>
        <p className="mt-6 text-gray-700 text-base leading-relaxed">{preview}</p>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block mt-6 text-blue-900 font-semibold text-lg hover:underline"
        >
          En savoir plus →
        </a>
      </div>
    </div>
  );
};

const AINewsSection: React.FC = () => {
  const newsArticles = [
    {
      title: "Les dernières avancées en Intelligence Artificielle",
      date: "8 Décembre 2024",
      imageUrl: "https://via.placeholder.com/600x400?text=News+1", // Placeholder
      preview:
        "Découvrez les dernières actualités du domaine de l'intelligence artificielle, des avancées révolutionnaires aux applications pratiques...",
      link: "",
    },
    {
      title: "Les tendances technologiques de 2024",
      date: "1 Décembre 2024",
      imageUrl: "https://via.placeholder.com/600x400?text=News+2", // Placeholder
      preview:
        "Explorez les tendances technologiques qui transforment l'Afrique et le monde en 2024, de l'IA aux innovations numériques.",
      link: "",
      reverse: true, // Alternate layout
    },
    {
      title: "MANIA Demo",
      date: "1 Décembre 2024",
      imageUrl: "https://via.placeholder.com/600x400?text=News+2", // Placeholder
      preview:
        "MANIA Day",
      link: "",
    },
  ];

  return (
    <section className="bg-gray-50 py-16 px-6 sm:px-12 lg:px-24">
      <div className="text-center mb-16">
        <h2 className="text-4xl font-extrabold text-blue-900 sm:text-5xl">
          Top Info Tech/IA de la Semaine
        </h2>
        <p className="mt-6 text-gray-600 max-w-4xl mx-auto text-lg">
          Retrouvez chaque semaine les actualités phares en technologie et
          intelligence artificielle, en lien avec notre événement MANIA Day.
        </p>
      </div>

      {/* News Cards */}
      <div className="max-w-7xl mx-auto">
        {newsArticles.map((news, index) => (
          <NewsCard
            key={index}
            title={news.title}
            date={news.date}
            imageUrl={news.imageUrl}
            preview={news.preview}
            link={news.link}
            reverse={news.reverse}
          />
        ))}
      </div>
    </section>
  );
};

export default AINewsSection;
